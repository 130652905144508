.main__header {
  width: 100vw;
  height: calc(75vh - 0rem);
  display: grid;
  place-items: center;
  margin-bottom: 3rem;
  background: var(--color-gray-600);
  background-image: url("../../images/background3.jpg"); /* Path to your background image */
  background-size: cover; /* Cover the entire container */
  background-position: center; /* Center the background image */
  margin-top: 5rem;
  animation: moveBackground 30s infinite linear; /* Add animation */
}

@keyframes moveBackground {
  0% {
    background-position: center center;
  }
  50% {
    background-position: center bottom;
  }
  100% {
    background-position: center center;
  }
}

.main__header-container {
  height: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
  justify-content: space-between;
  gap: 6rem;
}

.main__header-left {
  display: grid;
  place-items: center;
  position: relative;
}
.main__header-right {
  margin-top: -3rem;
}

.main__header-right h4 {
  margin-bottom: 1rem;
  color: var(--backround-color);
  font-weight: 600;
}

.main__header-right h1 {
  font-size: 2.5em;
  margin-bottom: 20px;
  line-height: 1.2;
}

.main__header-right p {
  margin: 1rem 0 3.5rem;
  font-size: 1.1rem;
  color: var(--backround-color);
}
.main__header-circle {
  width: 30rem;
  height: 30rem;
  background: linear-gradient(75deg, var(--color-secondary), transparent);
  border-radius: 50%;
  position: absolute;
  filter: blur(15px);
  transition: var(--transition);
}

.main__header-left:hover .main__header-circle {
  filter: none;
}

@media screen and (max-width: 1024px) {
  /*main header section*/

  .main__header {
    height: fit-content;
    padding: 12rem 0;
  }
  .main__hearder-container {
    gap: 0;
  }
  .main__header-circle {
    width: 16rem;
    height: 16rem;
  }
}

/* small devices (small screens) */

@media screen and (max-width: 600px) {
  /*MAIN HEADER*/
  .main__header {
    margin-top: 0;
    padding: 5rem 0 0; /* Adjusted padding to better fit content */
    height: fit-content; /* Changed to fit-content to adjust height based on content */
  }

  .main__header-container {
    grid-template-columns: 1fr; /* Stack content in one column */
    padding: 0 1rem; /* Added padding to prevent content from touching screen edges */
    text-align: center; /* Center align text for better readability */
  }

  .main__header-right {
    margin-top: 0; /* Reset margin for better alignment */
    margin-left: 0;
  }

  .main__header-circle {
    display: none;
  }

  .main__header-right h4,
  .main__header-right h1,
  .main__header-right p {
    margin: 0.3rem 0; /* Reduce margins for better fit */
  }
  .main__header-right p {
    margin-bottom: 3rem;
  }
}

/* /* Choose* */
.why-choose-us-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  padding: 40px;
  background-color: #f5f5f5;
}

.why-choose-us {
  width: 100%;
  max-width: 1200px;
  text-align: center; /* Center the h2 element */
  color: var(--text-color);
}

.why-choose-us h2 {
  margin-bottom: 40px; /* Add space between the h2 and the cards */
  /* margin-top: 10px; */
  color: var(--color-secondary);
}

.card-container {
  margin-top: 40px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 25px; /* Added gap for spacing */
}

.card {
  background-color: #f5f5f5;
  flex: 1 1 calc(33.333% - 40px); /* Adjust width to account for gap */
  max-width: calc(33.333% - 40px); /* Ensures cards do not exceed row width */
  border: 1px solid var(--color-secondary);
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin: 0 10px 20px 10px; /* Adds vertical and horizontal margin */
}

.card-body {
  padding: 30px;
}

.card-title {
  font-size: 1.25em;
  margin-bottom: 15px;
  color: #0056b3;
}

.card-text {
  font-size: 1em;
  color: var(--text-color);
}

.card-icon {
  font-size: 2em;
  color: var(--color-gray-600); /* Adjust icon color */
  margin-bottom: 10px; /* Space between icon and title */
}

.card:hover {
  transform: translateY(-10px); /* Move card up on hover */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Deeper shadow on hover */
  transition: var(--transition);
}

/* Ensure cards are responsive and stack on smaller screens */
@media (max-width: 1024px) {
  .card {
    flex: 1 1 calc(50% - 40px); /* Adjust width for smaller screens */
    max-width: calc(50% - 40px);
  }
  /* .why-choose-us-wrapper {
    padding-top: 6rem; 
  } */
}

@media (max-width: 600px) {
  .card {
    flex: 1 1 100%; /* Full width on smallest screens */
    max-width: 100%;
  }
  .why-choose-us h2 {
    margin-top: 0; /* Adjust top margin for medium screens */
  }
}

/* STRATEGIC FOCUS */

.strategic-focus-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 60vh;
  padding: 40px;
  background-color: var(--color-gray-600);
}

.strategic-focus {
  width: 100%;
  max-width: 1200px;
  text-align: center;
}

.strategic-focus h2 {
  margin-bottom: 60px;
  margin-top: 20px;
}
.strategic-focus p {
  margin-bottom: 40px;
}

.focus-card-container {
  display: flex;
  justify-content: space-between;
  gap: 40px;
}

.focus-card {
  flex: 1 1 calc(25% - 20px);
  border: 1px solid var(--color-secondary);
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  background-color: #fff;
}

.focus-card:hover {
  transform: translateY(-10px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  transition: var(--transition);
}

.focus-card-body {
  padding: 20px;
  text-align: center;
}

.focus-icon {
  font-size: 2em;
  color: var(--color-primary);
  margin-bottom: 10px;
}

.focus-card-title {
  font-size: 1.25em;
  margin-bottom: 15px;
  color: #0056b3;
}

.focus-card-text {
  font-size: 1em;
  color: var(--text-color);
}

@media (max-width: 1024px) {
  .focus-card-container {
    flex-wrap: wrap;
  }

  .focus-card {
    flex: 1 1 calc(50% - 20px);
    max-width: calc(50% - 20px);
    margin-bottom: 20px;
    margin-top: -20px;
  }
}

@media (max-width: 600px) {
  .focus-card {
    flex: 1 1 100%;
    max-width: 100%;
  }
}

/* Projects */

.projects-wrapper {
  padding: 20px;
  text-align: center;
  background-color: #f8f9fa;
  /*background-image: url('../../images/background3.jpg'); /* Path to your background image */
  /* background-size: cover; /* Cover the entire container */
  /* background-position: center; /* Center the background image */
  display: flex;
  flex-direction: column;
  justify-content: center; /* Center vertically */
  align-items: center; /* Center horizontally */
  min-height: 100vh; /* Ensures full height */
}

.projects-wrapper h2 {
  margin-bottom: 80px; /* Adjust spacing between h2 and projects */
  margin-top: -40px;
}

.projects-grid {
  width: 100%; /* Ensure grid takes full width of parent */
  max-width: 1200px; /* Example: Limit the maximum width of the grid */
  margin: 0 auto; /* Center the grid horizontally */
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 50px; /* Adjust the gap between project items */
  justify-items: center; /* Center items horizontally in grid */
}

.project-item {
  position: relative;
  overflow: hidden;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  width: 100%;
}

.project-item:hover {
  transform: translateY(-10px);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  transition: var(--transition);
}

.project-image {
  width: 100%;
  height: auto;
  display: block;
}

.project-info {
  padding: 20px;
  background-color: #fff;
  text-align: left;
}

.project-info h3 {
  margin-top: 0;
  font-size: 1.25em;
  color: #0056b3;
}

.project-info p {
  font-size: 1em;
  color: #080808;
}

/* Media Query for Tablet Screens */
@media screen and (max-width: 1024px) {
  .projects-grid {
    grid-template-columns: repeat(
      auto-fill,
      minmax(250px, 1fr)
    ); /* Adjust columns for smaller screens */
    gap: 20px; /* Adjust gap for smaller screens */
  }
  .projects-wrapper h2 {
    margin-top: 40px; /* Adjust top margin to create space between h2 and section above */
  }
}

/* Media Query for Mobile Screens */
@media screen and (max-width: 600px) {
  .projects-grid {
    grid-template-columns: 1fr; /* Display projects in a single column on smaller screens */
    gap: 15px; /* Adjust gap for smaller screens */
    padding: 10px; /* Adjust padding for smaller screens */
  }
  .projects-wrapper h2 {
    margin-top: 60px; /* Increase top margin further for smaller screens */
    margin-bottom: 10px; /* Reduce bottom margin for tighter spacing */
  }
}

/* FOOTER */

.footer {
  background-color: var(--color-primary);
  color: #fff;
  padding: 20px 0;
  text-align: center;
}

.footer-container {
  display: flex;
  justify-content: space-around;
  align-items: flex-start;
  flex-wrap: wrap;
  max-width: 1200px;
  margin: 0 auto;
}

.footer-section {
  margin: 20px;
  flex: 1;
  min-width: 200px;
}

.footer-section h4 {
  margin-bottom: 10px;
  font-size: 1.2em;
  color: #ffcc00; /* Yellow color for headings */
}

.footer-section p {
  font-size: 14px;
}

.footer-section p,
.footer-section ul {
  margin: 10px 0;
  line-height: 1.6;
}

.footer-section ul {
  list-style: none;
  padding: 0;
}

.footer-section ul li {
  margin: 5px 0;
}

.footer-section ul li a {
  color: #fff;
  text-decoration: none;
  transition: color 0.3s ease;
}

.footer-section ul li a:hover {
  color: #ffcc00; /* Yellow color on hover */
}

.social-icons a {
  margin: 0 10px;
  color: #fff;
  font-size: 1.5em;
  transition: color 0.3s ease;
}

.social-icons a:hover {
  color: #ffcc00; /* Yellow color on hover */
}

@media (max-width: 1024px) {
  .footer-container {
    flex-direction: column;
    align-items: center;
  }
}
