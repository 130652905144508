*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  list-style: none;
  text-decoration: none;
  box-sizing: border-box;
}
:root {
  --background-color: #ffffff;
  --hover-color: #ffff00;
  --text-color: #000000;

  --primary-hue: 210;
  --gray-hue: 240;
  --color-primary: hsl(var(--primary-hue), 100%, 50%);
  --color-primary-variant: hsl(var(var(--primary-hue), 100%, 65%));
  --color-secondary: #ffb116;
  --color-gray-100: hsl(var(--gray-hue), 47%, 94%);
  --color-gray-200: hsl(var(--gray-hue), 26%, 75%);
  --color-gray-300: hsl(var(--gray-hue), 24%, 64%);
  --color-gray-400: hsl(var(--gray-hue), 16%, 41%);
  --color-gray-500: hsl(var(--gray-hue), 44%, 25%);
  --color-gray-600: hsl(var(--gray-hue), 48%, 15%);

  --container-width-lg: 80%;
  --container-width-md: 90%;

  --transition: 500ms ease;
}

/* GENERAL STYLES*/

body {
  font-family: "Montserrat", sans-serif;
  color: var(--color-gray-200); /*text colour*/
  line-height: 1.7rem;
  overflow-x: hidden;
  background: var(
    --color-gray-600
  ); /*background colour of the website, change it to white or light blue*/
}

html {
  scroll-behavior: smooth;
}

.container {
  width: var(--container-width-lg);
  max-width: 1920px;
  margin-inline: auto;
}

h1,
h2,
h3,
h4,
h5 {
  line-height: 1, 2;
  color: var(
    --color-secondary
  ); /*colour almost white, might as well use white*/
}
h1 {
  font-size: 3rem;
}

h2 {
  font-size: 2rem;
}

a {
  color: var(--text-color);
}

img {
  display: block;
  object-fit: cover;
  width: 100%;
}

.btn {
  color: var(--color-gray-100);
  width: fit-content;
  margin: auto;
  padding: 0.9rem 2rem;
  background: var(--color-primary);
  border-radius: 1.5rem;
  transition: var(--transition);
}
.btn:hover {
  background: var(--color-secondary);
  color: var(--color-gray-600);
}
.btn.lg {
  padding: 1.2rem 3rem;
  border-radius: 2rem;
  font-size: 1.1rem;
}
.btn.sm {
  padding: 0.4rem 1.2rem;
  font-size: 0.9rem;
}
.btn.sm:hover {
  background: var(--color-gray-100);
}

.header {
  margin-top: 0rem;
  margin-bottom: 30px;
  height: 20rem;
  overflow: hidden;
  border-bottom: 2px solid var(--color-gray-400);
}

.header__container {
  width: 100%;
  /* height: 51vh;  */
  position: relative;
  display: grid;
  place-items: center;
  margin-bottom: 2rem;
}

.header__container-bg {
  position: absolute;
  width: 100%;
  height: 100%;
}

.header__content {
  position: relative;
  width: 44%;
  margin: 0 auto;
  text-align: center;
  color: var(--color-gray-100);
  padding: 3rem 0;
}

.header__content h2 {
  margin-bottom: 2rem;
}
.header__content p {
  color: var(--background-color);
  font-size: 0.9rem;
}

/* Medium devices (medium screens) */

@media screen and (max-width: 1024px) {
  .container {
    width: var(--container-width-md);
  }
  h1 {
    font-size: 2rem;
  }
  h2 {
    font-size: 1.6rem;
  }

  .header__container {
    margin-bottom: 80px;
  }
  .header__content {
    width: 76%;
    /* margin-bottom: 80px; */
  }
}

/* Medium devices (small screens screens) */

@media screen and (max-width: 600px) {
  h1 {
    font-size: 2.2rem;
    list-style: 1.3;
  }
  .img {
    display: block;
  }
  /* section{
        margin-top: 7rem;
    } */

  .header {
    height: fit-content;
  }

  .header__content {
    width: var(--container-width-md);
    /* padding: 6rem 0; */
  }

  .header__content p {
    font-size: 0.85rem;
  }
}
