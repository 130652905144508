.team {
  padding: 50px 20px;
  background-color: var(--color-gray-600);
  text-align: center;
}

.team h1 {
  font-size: 36px;
  color: var(--color-secondary);
  margin-top: 90px;
  margin-bottom: 80px;
}

.team-members {
  display: flex;
  justify-content: center;
  gap: 60px;
  flex-wrap: wrap;
  padding: 0 80px;
}

.team-member {
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  padding: 20px;
  max-width: 300px;
  text-align: center;
}

.team-photo {
  border-radius: 50%;
  width: 150px;
  height: 180px;
  object-fit: cover;
  margin-bottom: 20px;
  display: inline;
}

/* .sindi {
  padding: 30px;
} */

.team-member h2 {
  font-size: 24px;
  color: #333;
  margin-bottom: 10px;
}

.team-member h3 {
  font-size: 20px;
  color: #0056b3;
  margin-bottom: 15px;
}

.team-member p {
  font-size: 16px;
  color: #0c0c0c;
}

/* Media Queries for Small Devices */
@media (max-width: 600px) {
  .team-members {
    flex-direction: column;
    align-items: center;
  }

  .team-member {
    width: 100%;
    margin-bottom: 30px;
  }
}
