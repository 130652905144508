.services-container {
  padding: 100px 20px;
  max-width: 1200px;
  margin: 0 auto;
}

.services-container h2 {
  text-align: center;
  margin-bottom: 40px;
  font-size: 2.5em;
  color: var(--color-secondary);
  padding: 10px;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.services-container p {
  margin-bottom: 40px;
  text-align: center;
}

.services-wrapper {
  display: flex;
  justify-content: center;
}

.services-grid {
  display: grid;
  grid-template-columns: repeat(
    auto-fit,
    minmax(300px, 1fr)
  ); /* Adjust the card size dynamically */
  gap: 30px;
  width: 100%;
}

.service-card {
  background: #fff;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.service-card:hover {
  transform: translateY(-10px);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.service-image {
  width: 100%;
  height: 200px;
  object-fit: cover;
}

.service-info {
  padding: 20px;
  text-align: center;
}

.service-info h3 {
  font-size: 1.5em;
  margin-bottom: 10px;
  color: #0056b3;
}

.service-info p {
  font-size: 1em;
  color: var(--text-color);
}

@media (max-width: 1024px) {
  .services-container {
    padding: 80px 40px;
    margin-top: 40px;
  }
}

@media (max-width: 600px) {
  .services-container {
    padding: 80px 20px;
  }
}
