.about-page {
  padding: 0 40px;
  margin-top: 7rem;
}

.about__story-container,
.about__vision-container,
.about__mission-container {
  display: grid;
  gap: 40px;
  justify-content: center;
}

.about__story {
  margin-bottom: 40px;
}

.about__story-container {
  grid-template-columns: 40% 50%;
  height: 70vh;
  align-items: center;
}

.about__section-image {
  border-radius: 50%;
  overflow: hidden;
}

.about__section-image img {
  padding: 50px;
  background-color: white;
}

/* About vision */
.about__vision {
  /* margin-bottom: 80px; */
  height: 60vh;
}
.about__vision-container {
  grid-template-columns: 50% 40%;
  height: 50vh;
  /* align-items: center; */
}

.about__section-content {
  align-self: center;
}

.about__section-content h1 {
  font-size: 60px;
  margin-bottom: 20px;
  align-content: center;
}

.about__section-content p {
  font-size: 14px;
}

.about__vision-image {
  background: linear-gradient(75deg, var(--color-secondary), transparent);
  transition: var(--transition);
  border-radius: 50%;
}

/* About mission */
.about__mission {
  margin-bottom: 80px;
}

.about__mission-container {
  grid-template-columns: 40% 50%;
  height: 50vh;
}

.about__mission-image {
  background: linear-gradient(75deg, var(--color-secondary), transparent);
  transition: var(--transition);
  border-radius: 50%;
}

.about__section-content li {
  list-style-type: disc;
  font-size: 13px;
}

/* Our approach */
.our-approach {
  padding: 40px;
  background-color: var(--color-gray-600);
  /* color: #050505; */
  text-align: center;
}

.our-approach h1 {
  font-size: 45px;
  color: var(--color-secondary);
  margin-bottom: 20px;
}

.our-approach p {
  font-size: 18px;
  /* color: var(--background-color); */
  margin-bottom: 40px;
}

.cards {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
}

.cardss {
  background-color: var(--color-gray-600);
  border-radius: 8px;
  border: 1px solid var(--color-secondary);
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  padding: 20px;
  width: 300px;
  text-align: left;

  flex: 1 1 calc(33.333% - 40px); /* Adjust width to account for gap */
  max-width: calc(33.333% - 40px); /* Ensures cards do not exceed row width */

  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin: 0 10px 20px 10px; /* Adds vertical and horizontal margin */
}
.cardss body {
  padding: 20px;
}

.cardss h2 {
  font-size: 24px;
  color: #0056b3;
  margin-bottom: 10px;
}

.cardss p {
  font-size: 16px;
  /* color: var(--background-color); */
  line-height: 1.6;
}

.cardss:hover {
  transform: translateY(-10px); /* Move card up on hover */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Deeper shadow on hover */
  transition: var(--transition);
}

/* Media Queries for Medium Devices (Tablets) */
@media only screen and (min-width: 601px) and (max-width: 1023px) {
  /* Styles for screens between 601px and 1023px */
  .about-page {
    margin-top: 7rem;
  }
  .about__story-container {
    display: flex;
    flex-direction: column;
    height: 100vh;
  }

  .about__section-image {
    width: 60%;
    margin-bottom: 40px;
  }

  .about__section-image img {
    display: none;
  }

  .about__section-content h1 {
    margin-bottom: 30px;
    font-size: 50px;
    text-align: center;
  }

  .vision-content p {
    color: beige;
  }

  .about__vision-container,
  .about__mission-container {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    /* height: 100vh; */
  }

  .vision-content {
    position: absolute;
    top: 50%; /* Adjust this value to control the vertical positioning */
    left: 50%; /* Adjust this value to control the horizontal positioning */
    transform: translate(
      -50%,
      -50%
    ); /* Centers the content within the container */
    z-index: 2; /* Ensures the content appears above the image */
    padding: 20px; /* Optional: adds some padding around the content */
    text-align: center;
    /* box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); */
  }

  .about__vision-image {
    position: relative;
    z-index: 1; /* Ensures the image is behind the content */
    width: 80%; /* Adjust width as needed */
    height: 100%; /* Adjust height as needed */
    object-fit: cover;
  }

  .about__mission-container {
    height: 50vh;
  }

  .mission-image {
    display: none;
  }

  .our-approach {
    padding: 10px;
  }

  .our-approach p {
    font-size: 14px;
  }

  .cards {
    display: grid;
    grid-template-columns: repeat(2, 1fr); /* Creates 2 equal-width columns */
    gap: 20px; /* Adds space between the cards */
    justify-items: center;
  }

  .cardss {
    box-sizing: border-box; /* Ensures padding and borders are included in the width calculation */
    max-width: 100%;
    text-align: center;
  }
}

@media only screen and (max-width: 600px) {
  /* .about__vision {
    margin-bottom: 40px;
  } */
  .about__section-content h1 {
    font-size: 36px;
    text-wrap: nowrap;
  }
  .about__section-content p {
    /* text-align: center; */
  }
  .about__vision {
    margin-bottom: 80px;
  }
  .about__mission {
    height: 60vh;
  }
  .our-approach h1 {
    font-size: 36px;
  }
  .cards {
    display: grid;
    grid-template-columns: repeat(1, 1fr); /* Creates 2 equal-width columns */
    gap: 20px; /* Adds space between the cards */
    justify-items: center;
  }
}
