.contact-section {
  padding: 110px 20px;
  text-align: center;
}

.container.contact__container {
  max-width: 70%;
  display: grid;
  grid-template-columns: 30% 58%;
  gap: 12%;
  margin-top: 80px;
}

.contact__options {
  display: flex;
  flex-direction: column;
  gap: 1.2rem;
}
.contact__option {
  background: var(--background-color);
  padding: 1.2rem;
  border-radius: 1.2rem;
  text-align: center;
  border: 1px solid transparent;
  transition: var(--transition);
  color: var(--color-primary);
}

.contact__option:hover {
  background: transparent;
  border-color: var(--color-secondary);
}

.contact__option-icon {
  font-size: 1.5rem;
  margin-bottom: 0.5rem;
}

.contact__option a {
  margin-top: 0.7rem;
  display: inline-block;
  font-size: 0.8rem;
  color: var(--color-primary);
}

.contact__option h5 {
  display: flex;
}

/*===============FORM==============*/

form {
  display: flex;
  flex-direction: column;
  gap: 1.2rem;
}
input,
textarea {
  width: 100%;
  padding: 1.5rem;
  border-radius: 0.5rem;
  background: var(--background-color);
  border: 2px solid var(--color-secondary);
  resize: none;
}

/* Media Queries for Medium Devices */
@media (max-width: 1024px) {
  .container.contact__container {
    grid-template-columns: 1fr;
    gap: 2rem;
  }
}

/* Media Queries for Small Devices */
@media (max-width: 600px) {
  .container.contact__container {
    width: var(--container-width-md);
  }
}
