nav{
    height: 5rem;
    width: 100vw;
    background: var(--background-color); /*background color of the nav*/
    display: grid;
    place-items: center;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 99;
}


/* ONLY SHOW ON MEDIUM DEVICES AND SMALL DEVICES*/
.nav__toogle-btn{
    display: none;
}


.nav__container{
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
}

.logo{
    width: 24rem;
    display: block;
    height: 100%
}

.nav__links{
    display: flex;
    gap: 3.5rem;
    align-items: center;
}

.nav__container a{
    transition: var(--transition);
}
.nav__links a:hover{
    color: var(--color-secondary);

}

.active-nav{
    position: relative;
}
.active-nav::after{
    content: '';
    display: block;
    width: 1.2rem;
    height: 1.2rem;
    /*background: var(--background-color);*/
    position: absolute;
    left: calc(50%-0.6rem);
    transform: rotate(45deg);
    margin: 0.9rem;
}



/* MEDIA QUERIES FOR MEDIUM AND SMALL DEVICES*/
@media screen and (max-width: 1024px){

    .nav__toogle-btn{
    display: inline-block;
    background: transparent;
    font-size: 1.8rem;
    cursor: pointer;

    }
    .logo{
        width: 15rem;
        display: block;
        height: 60%;


    }
    .nav__links a:hover{
        color: var(--color-secondary);
    }

    .nav__toogle-btn svg{
        color: var(--color-secondary); /*on phones its white consider changing it to blue*/
    }
    .nav__links{
        position: absolute;
        top: 100%;
        right: 0;
        flex-direction: column;
        gap: 0;
    }
    .active-nav, .active-nav::after{
        display: none;
    }
    .nav__links li{
        height: 4rem;
        width: 100%;
        box-shadow: -2rem 2rem 5rem rgba(0,0,0,0.4);

    }
    .nav__links li a{
        background: var(--color-primary);
        height: 100%;
        width: 100%;
        display: flex;
        align-items: center;
        padding: 1rem 5rem 1rem 3rem;
    }
    
    
    .show__nav{
        display: flex;
    }

    .hide__nav{
        display: none;
    }

}